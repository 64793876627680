<template>
  <div class='control-container'>
    <v-card variant='elevated' v-if="selectedProject">
      <v-card :height="715">
        <v-card variant="elevated" color="orange-darken-4" class="text-h4 pa-4">
          {{ selectedProject.name }}
          <br>
          <v-chip variant="flat" color="primary" style="font-weight: bold;">{{ projectState }}</v-chip>
        </v-card>
        
        <div class="progress-container">
          <v-card-item>
            <v-card-title>Anotováno</v-card-title>
            <v-progress-circular
              :rotate="0"
              :size="100"
              :width="15"
              :model-value="projectProgress.annotated"
              color="green"
            >
              {{ this.localCases.filter(c => c.done).length }} / {{ this.localCases.length }}
            </v-progress-circular>
          </v-card-item>
          <v-card-item>
            <v-card-title>Zkontrolováno</v-card-title>
            <v-progress-circular
              :rotate="0"
              :size="100"
              :width="15"
              :model-value="projectProgress.checked"
              color="red"
            > 
              {{ this.localCases.filter(c => c.checked).length }} / {{ casesToBeChecked }}
            </v-progress-circular>
          </v-card-item>
        </div>

        <v-divider/>
        <div class="button-container">
          <v-btn v-if="selectedProject.state == 0" color="orange-darken-4" prepend-icon="mdi-cards-playing" variant="flat" @click="startAnnotation">Spustit anotaci</v-btn>

          <div v-if="selectedProject.state == 2">
            <v-btn color="orange-darken-4" prepend-icon="mdi-cards-playing" variant="flat" @click="startCheck">Spustit kontrolu</v-btn>
            <v-checkbox v-model="advancedChecker" class="pe-2" label="Zkušený dodavatel"/>
          </div>

          <v-btn v-if="selectedProject.state == 4" color="orange-darken-4" prepend-icon="mdi-check-outline" variant="flat" @click="this.finishProjectDialog = true">Označit jako dokončené</v-btn>

          <v-btn prepend-icon="mdi-file-search-outline" variant="outlined" @click="goToProject">Nahlédnout</v-btn>
          <v-btn :disabled="selectedProject.state != 5 || exportLoading" @click="downloadExport" variant="outlined">
            <v-icon v-if="!exportLoading" left class="mr-2">mdi-export-variant</v-icon>
            <div v-if="!exportLoading">Exportovat</div>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
              size="20"
            />
          </v-btn>

          <a v-show="downloadUrl" ref="downloadLink" :href="downloadUrl" download target="_blank" class="download-link">
            Pokud stahování nezačne automaticky, klikněte zde.
          </a>
          
          
        </div>
        <v-divider/>
        <div>
          <v-select
            :items="users"
            item-title="name"
            item-value="id"
            v-model="annotator"
            label="Anotuje"
            :disabled="selectedProject.state != 0"
            />
          <v-select
            :items="users"
            item-title="name"
            item-value="id"
            v-model="checker"
            label="Kontroluje"
            :disabled="selectedProject.state > 2"
            />
        </div>
      </v-card>
    </v-card>
  </div>
  <v-snackbar
      v-model="error"
      :timeout="4000"
      color="error"
    >
    {{ errorMessage }}
  </v-snackbar>


  <!-- Finish project dialog -->
  <v-dialog v-model="finishProjectDialog" max-width="500px">
    <v-card>
      <div class="py-12 text-center">
        <v-icon
          class="mb-6"
          color="error"
          icon="mdi-robot-excited"
          size="128"
        ></v-icon>
        <div class="text-h3 font-weight-bold">Ještě než dokončíš..</div>
        <div class="text-h5 font-weight-bold">Zkontroluj důkladně, zda jsou všechny anotace vpořádku!</div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="orange darken-4" text @click="finishProjectDialog = false">ještě to zkontroluju</v-btn>
        <v-btn color="orange darken-4" text @click="finishProject">Dokončit projekt</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getFirestore, collection, getDocs, doc, updateDoc, addDoc, writeBatch, query} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref as fbRef } from "firebase/storage";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref } from 'vue';
import { db, app } from '../../firebase';

export default {
  emits: ['updated'],
  props: {
    selectedProject: Object,
    users: Array,
  },
  data(){
    const annotator = ref(null);
    const checker = ref(null);
    const finishProjectDialog = ref(false);
    const functions = getFunctions(app, 'europe-west3')
    return{
      localSelectedProject: [],
      localCases: [],
      annotator,
      checker,
      advancedChecker: false,
      error: false,
      errorMessage: '',
      finishProjectDialog,
      exportLoading: false,
      functions,
    }
  },
  methods: {
    async update() {
      if (!this.selectedProject){
        return;
      }

      const Project = {...this.localSelectedProject}
      Project.annotate = this.annotator;
      Project.check = this.checker;

      // Update the selected project
      this.downloadUrl = null;
      const projectRef = doc(db, 'projects', this.selectedProject.id);
      await updateDoc(projectRef, Project);
      this.$emit('updated');
    },

    async loadCases() {
      if (this.selectedProject){
        const db = getFirestore();
        const casesRef = collection(db, 'projects', this.selectedProject.id, 'cases');
        const casesSnapshot = await getDocs(casesRef);
        const cases = casesSnapshot.docs.map(doc => {
          const data = doc.data();
          data.id = doc.id; // Add the 'id' property to the data object
          return data;
        });
        this.localCases = cases;
      }
      else{
        this.localCases = [];
      }
    },

    assignedUsersHandle() {
      if (!this.users){
        return;
      }
      if (this.selectedProject){
        if (this.selectedProject.annotate){
          this.annotator = this.users.find(u => u.id === this.selectedProject.annotate.id).id;
        }
        else{
          this.annotator = '';
        }
        if (this.selectedProject.check){
          this.checker = this.users.find(u => u.id === this.selectedProject.check.id).id;
        }
        else{
          this.checker = '';
        }
      }  
    },
    

    goToProject(){
      this.$router.push({ name: 'Projekt new', params: { id: this.selectedProject.id } });
    },
    startAnnotation(){
      if (this.annotator == null || this.annotator === ''){
        this.errorMessage = 'Není vybrán anotátor!'
        this.error = false;
        this.error = true;
        return;
      }
      if (!this.localSelectedProject){
        this.errorMessage = 'Není vybrán projekt!'
        this.error = false;
        this.error = true;
        return;
      }

      this.localSelectedProject.annotate = this.annotator;
      this.localSelectedProject.state = 1;
      this.update();
      this.sendAssigmentEmail(0);
    },

    async startCheck() {
      if (!this.checker || !this.localSelectedProject) {
        this.errorMessage = 'Není vybrán kontrolor nebo projekt!';
        this.error = true;
        return;
      }

      this.localSelectedProject.check = this.checker;
      this.localSelectedProject.state = 3;

      const projectId = this.selectedProject.id; // Assuming selectedProject is defined

      // Calculate ratio and random cases (assuming getRatio is implemented)
      const ratio = this.getRatio();
      const numCases = Math.floor(this.localCases.length * ratio);
      const randomCases = this.localCases.sort(() => Math.random() - Math.random()).slice(0, numCases).map(c => c.id);

      try {
        // Get all case documents in one batch
        const casesQuery = query(collection(db, 'projects', projectId, 'cases'));
        const casesSnapshot = await getDocs(casesQuery);

        // Create a write batch
        const batch = writeBatch(db);

        casesSnapshot.forEach((doc) => {
          const caseId = doc.id;
          const caseData = doc.data();

          caseData.check = caseData.annotation;
          caseData.checking = false;

          // Set 'checking' for random cases
          if (randomCases.includes(caseId)) {
            caseData.checking = true;
          }

          // Update the document in the batch
          batch.update(doc.ref, caseData);
        });

        // Commit the batch
        await batch.commit();

        // Update other data and send emails (modify as needed)
        await this.update();
        this.sendAssigmentEmail(1); // Assuming sendAssigmentEmail is implemented

        // Success message (optional)
        this.errorMessage = 'Kontrola zahájena!';
        this.error = false;
      } catch (error) {
        console.error('Error in startCheck:', error);
        this.errorMessage = 'Chyba při zahajování kontroly!';
        this.error = true;
      }
    },
    getRatio(){
      const length = this.localCases.length;
      if (this.advancedChecker){
        if (length <= 50) {
          return 0.25;
        } else if (length <= 100) {
          return 0.19;
        } else if (length <= 200) {
          return 0.10;
        } else if (length <= 500) {
          return 0.08;
        } else if (length <= 1000) {
          return 0.06;
        } else if (length <= 1500) {
          return 0.04;
        } else {
          return 0.03;
        }
      } else{
        if (length <= 50) {
          return 0.5;
        } else if (length <= 100) {
          return 0.38;
        } else if (length <= 200) {
          return 0.21;
        } else if (length <= 500) {
          return 0.16;
        } else if (length <= 1000) {
          return 0.12;
        } else if (length <= 1500) {
          return 0.08;
        } else {
          return 0.06;
        }
      }
    },
    finishProject(){
      this.localSelectedProject.state = 5;
      this.update();
      this.finishProjectDialog = false;
    },
    async downloadExport() {
      this.exportLoading = true;
      const createProjectExport = httpsCallable(this.functions, 'createProjectExportNew', {timeout: 900000});
      
      try {
        const storage = getStorage();
        const result = await createProjectExport({ projectId: this.localSelectedProject.id });
        console.log("result: ",result)
        const path = result.data;
        if (!path || path.startsWith('/')) {
          throw new Error(`Invalid path: ${path}`);
        }
        const storageRef = fbRef(storage, path);
        const url = await getDownloadURL(storageRef);
        this.downloadUrl = url
        this.$nextTick(() => {
          this.$refs.downloadLink.click();
        });

      } catch (error) {
        console.error("Error calling downloadExport:", error);
      }
      this.exportLoading = false;
    },
    sendAssigmentEmail(role){
      const mailRef = collection(db, "mail");
      let user;
      let role_text;

      if (role == 0){
        user = this.users.find(u => u.id === this.annotator);
        role_text = 'anotátor';
      } else {
        user = this.users.find(u => u.id === this.checker);
        role_text = 'kontrolor';
      }

      console.log(user, role_text)
      if (!user) return;
        

      addDoc(mailRef, {
        template: {
          name: "project-assigned",
          data: {
            username: user.name,
            project_name: this.selectedProject.name,
            role: role_text,
          },
        },
        to: user.email,
      })
    },
  },
  computed: {
    projectProgress(){
      if (this.localCases.length > 0){
        const annotatedTotal = this.localCases.length;
        const annotated = this.localCases.filter(c => c.done).length;
        let checkedTotal = this.localCases.filter(c => c.checking).length;
        const checked = this.localCases.filter(c => c.checked).length;
        if (checkedTotal == 0){
          checkedTotal = 1;
        }
        return {
          'annotated': Math.round((annotated / annotatedTotal) * 100),
          'checked': Math.round((checked / checkedTotal) * 100),
        };
      }
      else{
        return {
          'annotated': 0,
          'checked': 0,
        };
      }
    },
    projectState(){
      if (this.localSelectedProject){
        switch(this.localSelectedProject.state){
          case 0:
            return 'Nový';
          case 1:
            return 'Probíhá anotace';
          case 2:
            return 'Anotace dokončena';
          case 3:
            return 'Probíhá kontrola';
          case 4:
            return 'Kontrola dokončena';
          case 5:
            return 'Dokončený';
          case 6:
            return 'Zrušený';
          default:
            return 'Neznámý';
        }
      }
      else{
        return 'Neznámý';
      }
    },
    usersComputed(){
      return this.users.map(user => {
        return {
          id: user.id,
          name: user.name,
        };
      });
    },
    casesToBeChecked(){
      if (this.localSelectedProject.state > 2){
        return this.localCases.filter(c => c.checking).length;
      }
      else{
        return '?';
      }
    }
  },
  watch: {
    selectedProject: {
      immediate: true,
      handler() {
        this.localSelectedProject = this.selectedProject
        this.downloadUrl = null;
        this.loadCases();
        this.assignedUsersHandle();
      }
    },
  }
}
</script>