<template>
  <v-btn @click="dialog=true">Vytvořit</v-btn>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="headline"> Vytvořit Projekt</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="state.name"
            label="Nazev projektu *"
            required
            variant="outlined"
            counter="15"
            :rules="[
              v => !!v || 'Field is required',
              v => (v && v.length <= 15) || 'Name must be less than 15 characters',
            ]"
            
          />
          <v-text-field
            type="datetime-local"
            v-model="state.deadline"
            label="Odevzdani *"
            variant="outlined"
            required
            :rules="[
              v => !!v || 'Field is required',
            ]"
            />
          <v-select
            variant="outlined"
            v-model="state.annotate"
            :items="users"
            :item-props="true"
            label="Anotator"
            required
            clearable
            
          />
          <v-file-input
            variant="outlined"
            v-model="state.file"
            label="Soubor *"
            required
            accept=".zip"
            :rules="[
              v => !!v || 'Field is required',
              ]"
          />
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="blue"
            class="mb-0"
          ></v-progress-linear>
          <v-snackbar
              v-model="snackbar"
              :timeout="5000"
            >
              {{ snackbarText }}
          </v-snackbar>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        
        <v-btn color="blue darken-1" text @click="dialog=false">Zrušit</v-btn>
        <v-btn color="blue darken-1" text @click="submit">Vytvořit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>

import { onMounted, ref } from 'vue';
import { reactive } from 'vue'
import { collection,getDocs, setDoc, getDoc, doc, deleteDoc, addDoc } from '@firebase/firestore';
import { db, app } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getStorage, ref as fbRef, uploadBytesResumable } from "firebase/storage";


export default {
  emits: {
    'project-created': null
  },
  setup(_, {emit}){
    const dialog = ref(false);
    const form = ref(null);
    const initialState = {
      name: null,
      deadline: null,
      annotate: null,
      file: null,
    }

    const loading = ref(false);
    const snackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');

    const users = ref([]);

    const state = reactive({...initialState});

    const loadUsers = async () => {
      const userDocs = await getDocs(collection(db, 'users'));
      users.value = userDocs.docs.map(user => {
        return {
          title: user.data().email,
          value: user.id,
          subtitle: `${user.data().name} | ${user.data().xp} XP`
        };
      });
    }

    onMounted(loadUsers);

    const submit = async () => {
      const valid = await form.value.validate()
      const functions = getFunctions(app, 'europe-west3');
      const auth = getAuth();
      const user = auth.currentUser;

      if (!valid.valid || !user){
        return
      }

      loading.value = true;
      snackbarText.value = 'Nahrávám soubor...';
      snackbar.value = true;

      let newProjectDocRef = null;

      try {
        // create empty project first
        const projectsCollectionRef = collection(db, "projects");
        newProjectDocRef = doc(projectsCollectionRef);

        await setDoc(newProjectDocRef, {});
        const projectId = newProjectDocRef.id;

        const fileURL = await uploadFile(projectId);

        snackbarText.value = 'Vytvářím projekt...';

        const idToken = await user.getIdToken();

        const createProjectFunction = httpsCallable(functions, 'createProject');

        await createProjectFunction({
          projectId: projectId,
          name: state.name,
          annotate: state.annotate,
          file: fileURL,
          deadline: state.deadline,
          idToken: idToken,
        });

        snackbarText.value = 'Projekt vytvořen!';
        snackbarColor.value = 'success';

        if (state.annotate){
          await sendAssigmentEmail();
        }
        
        state.name = null;
        state.deadline = null;
        state.annotate = null;
        state.file = null;
                
      } catch (error) {
        console.log(error.message)
        snackbarText.value = 'Nastala chyba: ' + error.message;
        snackbarColor.value = 'error';
        
        await deleteDoc(newProjectDocRef);
      } finally {
        emit('project-created');
        dialog.value = false;
        loading.value = false;
        snackbar.value = false;
      }
    };

    const sendAssigmentEmail = async () => {
      const mailRef = collection(db, "mail");
      let user;
      let role_text;

      const userDoc = await getDoc(doc(db, 'users', state.annotate));
      user = userDoc.data();
      role_text = 'anotátor';


      console.log(user, role_text)
      if (!user) return;
        

      addDoc(mailRef, {
        template: {
          name: "project-assigned",
          data: {
            username: user.name,
            project_name: state.name,
            role: role_text,
          },
        },
        to: user.email,
      })
    };
    
    const uploadFile = (projectId) => {
      return new Promise((resolve, reject) => {
        // Get a reference to the storage service
        const storage = getStorage();

        // Create a storage reference from our storage service
        const filePath = `projects/${projectId}/${state.file[0].name}`;
        const storageRef = fbRef(storage, filePath);

        // Start the upload
        const uploadTask = uploadBytesResumable(storageRef, state.file[0]);

        uploadTask.on('state_changed', 
          (snapshot) => {
            console.log(snapshot)
            // Handle the upload progress
          }, 
          (error) => {
            // Handle unsuccessful uploads
            console.log(error)
            reject(error);
          }, 
          () => {
            // Handle successful uploads on complete
            resolve(filePath);
          }
        );
      });
    }

    return {
      dialog, state, users, submit, uploadFile, form, loading, snackbar, snackbarText, snackbarColor
    }
  },
} 
</script>