<template>
    <main class="login">
            <v-card
            class="login-card mx-auto pa-12 pb-8"
            elevation="8"
            max-width="448"
            max-height="600"
            rounded="lg"
            >
            <v-form @submit.prevent="login" class="login">
                <img
                    src="../assets/aspena-logo.svg"
                    height="150"
                    />
                <div class="text-subtitle-1 text-medium-emphasis">Účet</div>

                <v-text-field
                    density="compact"
                    placeholder="Email"
                    prepend-inner-icon="mdi-email-outline"
                    variant="outlined"
                    v-model="login_form.email"
                ></v-text-field>

                <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                    Heslo
                </div>

                <v-text-field
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="visible ? 'text' : 'password'"
                    density="compact"
                    placeholder="Zadejte heslo"
                    prepend-inner-icon="mdi-lock-outline"
                    variant="outlined"
                    v-model="login_form.password"
                    @click:append-inner="visible = !visible"
                ></v-text-field>

            
                <v-btn block
                class="mb-8"
                color="blue"
                size="large"
                variant="outlined" type="submit">Přihlásit se</v-btn>
            </v-form>
        </v-card>
    </main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

    export default {
        setup () {
            const login_form = ref({});
            const store = useStore();
            const visible = ref(false)

            const login = () => {
                store.dispatch('login', login_form.value)
            }
 
            return {
                visible,
                login_form,
                store,
                login,
            }
        }
    }
</script>

<style lang="less">
    .login-card{
        margin-top: 4%;
    }

</style>