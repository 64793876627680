import { createVuetify } from 'vuetify'
import {
  VDataTable,
  VDataTableServer,
  VDataTableVirtual,
} from "vuetify/labs/VDataTable";
import { VDatePicker } from 'vuetify/labs/VDatePicker'


import 'vuetify/styles'
import * as baseComponents from 'vuetify/components'
import * as directives from 'vuetify/directives'


import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

// import colors from 'vuetify/lib/util/colors';

const customDarkTheme = {
  dark: true,
  colors: {
    background: "#15202b",
    surface: "#25303b",
    primary: "#fafafa",
    secondary: "#ed7405",
    error: "#f44336",
    info: "#2196F3",
    success: "#ed7405",
    warning: "#fb8c00",
  },
};

export default createVuetify({
  components: {
    ...baseComponents,
    VDataTable,
    VDataTableServer,
    VDataTableVirtual,
    VDatePicker,
  },
  directives,
  theme: {
    defaultTheme: 'customDarkTheme',
    themes: {
      customDarkTheme,
    },
  },
  icons: {
    iconfont: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})