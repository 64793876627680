<template>
    <v-navigation-drawer v-if="currentUser"
      color="background"
      style="padding-bottom: 1rem"
      expand-on-hover
      rail
    >
      <v-list>
        <img
          src="@/assets/aspena-logo-a.svg"
          height="35"
          />
        <v-list-item
          prepend-icon="mdi-shield-account"
          :subtitle=this.currentUser.name
        ></v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <router-link to="/projects" custom v-slot="{ navigate }">
          <v-list-item @click="navigate" prepend-icon="mdi-list-box" title="Projekty"></v-list-item>
        </router-link>
        <router-link to="/fav-diagnosis" custom v-slot="{ navigate }">
          <v-list-item @click="navigate" prepend-icon="mdi-star-cog" title="Oblíbené diagnózy"></v-list-item>
        </router-link>
      </v-list>
      
      <template v-slot:append>
        <v-list density="compact" nav>
          <router-link v-if="isAdmin" to="/admin" custom v-slot="{ navigate }">
            <v-list-item @click="navigate" prepend-icon="mdi-hat-fedora" title="Správce"></v-list-item>
          </router-link>
          <router-link to="/account" custom v-slot="{ navigate }">
            <v-list-item @click="navigate" prepend-icon="mdi-account-card" title="Účet"></v-list-item>
          </router-link>
          <router-link to="/logout" custom v-slot="{ navigate }">
            <v-list-item @click="navigate" prepend-icon="mdi-logout" title="Odhlásit se"></v-list-item>
          </router-link>
        </v-list>
      </template>
    </v-navigation-drawer>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { RouterLink } from 'vue-router'

export default {
  components: {
    RouterLink,
  },

  setup() {
    const store = useStore()
    const currentUser = computed(() => store.getters.currentUser);
    const isAdmin = computed(() => currentUser.value.admin);
    const collapsed = ref(true);
    
    
    return {
      currentUser,
      collapsed,
      isAdmin
    }
  },
}
</script>

<style lang="less">
    
</style>