// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCvzT5Wq5RE6hrFH4sgUn7E1R2IUVNf42o",
  authDomain: "aspena-datapointer.firebaseapp.com",
  projectId: "aspena-datapointer",
  storageBucket: "aspena-datapointer.appspot.com",
  messagingSenderId: "912934019700",
  appId: "1:912934019700:web:95707f1f72b6ec58372721"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { app, auth, db };