import { createStore } from "vuex";
import router from '../router'
import { auth, db } from '../firebase'
import {
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore';

export default createStore({
  state: {
    user: null
  },
  getters: {
    currentUser(state) {
      return state.user;
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    clearUser(state){
      state.user = null;
    }
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const user = await signInWithEmailAndPassword(auth, email, password);

        commit("setUser", user);
        router.push('/')
      } catch (error) {
        console.log(error);
        alert('Špatné přihlašovací údaje');
      }
    },
    async logout({ commit }) {
      try {
        await signOut(auth);
        commit("setUser", null);
      } catch (error) {
        console.log(error);
      }
    },

    fetchUser({ commit, dispatch }) {
      return new Promise((resolve) => {
        auth.onAuthStateChanged(async user => {
          if (user === null) {
            commit('clearUser')
            resolve();
          } else {
            const userDoc = doc(db, 'users', user.uid);
            const userSnap = await getDoc(userDoc);

            if (userSnap.exists() && userSnap.data().active) {
              commit('setUser', { uid: user.uid, ...userSnap.data() });
              resolve();
            } else {
              dispatch('logout');
              router.replace('/login')
              resolve();
            }

            // if (router.isReady() && router.currentRoute.value.path === '/login'){
            //   router.push('/');
            // }
          }
        })
      })
    }
  },
  modules: {},
});