import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue"
import ProjectsView from '../views/ProjectsView.vue'
import { auth } from '../firebase'
import { useStore } from 'vuex'



const routes = [
  {
    path: "/",
    redirect: '/projects',
    name: "Přehled",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
    
  },
  {
    path: '/projects',
    name: 'Projekty',
    component: ProjectsView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/project/:id",
    name: "Projekt",
    component: () =>
      import("../views/ProjectView.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/project-new/:id",
    name: "Projekt new",
    component: () =>
      import("../views/ProjectViewNew.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/account",
    name: "Účet",
    component: () =>
      import("../views/UserView.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/fav-diagnosis",
    name: "Oblíbené diagnózy",
    component: () =>
      import("../views/FavDiagnosisView.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/admin",
    name: "Správce",
    component: () =>
      import("../views/AdminView.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/login',
    name: 'Přihlášení',
    component: LoginView,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import("../components/LogoutComponent.vue"),
    meta: { requiresAuth: true } // If the user should be logged in to access this route
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  const checkUser = async () => {
    if (!auth.currentUser || !store.state.user) {
      await store.dispatch('fetchUser');
    }

    if (requiresAuth && (!auth.currentUser)) {
      next('/login');
    } else if (to.path === '/login' && auth.currentUser) {
      next('/');
    } else if (requiresAdmin && store.state.user.admin !== true) {
      next('/');
    } else {
      next();
    }
  };

  return new Promise(resolve => {
    checkUser().then(resolve);
  });
});

export default router;
