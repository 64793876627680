<template>
  <div class="list-container">
    <create-project @project-created="loadProjects" />
    <v-data-table 
      :headers="headers" 
      :items="flattenedProjects"
      :loading="loading"
      :height="650"
      :row-height="20"
      >
      <template v-slot:item="{ item }">
        <tr style="text-align: left" @click="selectProject(item)">
            <td>{{ item.name }}</td>
            <td><v-chip 
               style="cursor: pointer;"
               :class="'s'+item.state_num"
               >{{ item.state }}</v-chip></td>
            <td>{{ item.created }}</td>
            <td>{{ item.deadline }}</td>
            <td>{{ item.annotate }}</td>
            <td>{{ item.check }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
  <ProjectControl ref="projectController" 
        :selectedProject="selectedProject"
        :users="users"
        @updated="loadProjects"
        />
</template>

<script>
import { ref } from 'vue';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import CreateProject from './CreateProject.vue';
import ProjectControl from './ProjectController.vue';

export default {
  setup(){
    const loading = ref(true);
    return {
      loading
    }
  },
  components: {
    CreateProject,
    ProjectControl,
  },
  props: {
    projects: Array
  },
  data() {
    return {
      selectedProject: null,
      localProjects: [],
      headers: [
        { title: 'Název projektu', key: 'name' },
        { title: 'Stav', key: 'state' },
        { title: 'Vytvořeno', key: 'created' },
        { title: 'Odevzdání', key: 'deadline' },
        { title: 'Anotátor', key: 'annotate' },
        { title: 'Kontrolor', key: 'check' },
      ],
      dialog: false,
      users: [],
    };
  },
  computed: {
    flattenedProjects() {
      return this.localProjects.map(project => {
        const createdDate = new Date(project.created.seconds * 1000);
        const deadlineDate = new Date(project.deadline.seconds * 1000);

        let state;
        switch(project.state){
          case 0:
            state = 'Nový'
            break;
          case 1:
            state = 'Anotace'
            break;
          case 2:
            state = 'Anotace dokončena'
            break;
          case 3:
            state = 'Kontrola'
            break;
          case 4:
            state = 'Kontrola dokončena'
            break;
          case 5:
            state = 'Dokončeno'
            break;
          case 6:
            state = 'Zrušeno'
            break;
          default:
            state = 'Neznámý'
        }

        return {
          id: project.id,
          name: project.name,
          created: `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`,
          deadline: `${deadlineDate.toLocaleDateString()} ${deadlineDate.toLocaleTimeString()}`,
          action: 'action',
          annotate: project.annotate ? project.annotate.name : 'Nepřiřazen', 
          check: project.check ? project.check.name : 'Nepřiřazen', 
          state: state,
          state_num: project.state
        };
      })
    }
  },


  async created() {
    await this.loadUsers();
    this.loadProjects();

  },
  methods: {
    selectProject(item) {
      this.selectedProject = this.localProjects.find(p => p.id === item.id);
    },

    async loadProjects() {
      this.loading = true;
      const db = getFirestore();
      const projectsRef = collection(db, 'projects');

      const projectDocs = await getDocs(projectsRef);

      this.localProjects = await Promise.all(projectDocs.docs.map(async (doc_instance) => {
        const projectData = doc_instance.data();

        const rights = {
          annotate: this.getUserById(projectData.annotate),
          check: this.getUserById(projectData.check),
        }
        return { id: doc_instance.id, ...projectData, ...rights };
      }));

      this.localProjects.sort((a, b) => {
        const createdDateA = new Date(a.created.seconds * 1000);
        const createdDateB = new Date(b.created.seconds * 1000);
        return createdDateB - createdDateA;
      });

      this.loading = false;
    },

    getUserById(userId) {
      if (!userId){
        return '';
      }
      const user = this.users.find(u => u.id === userId);
      return user ? user : '';
    },

    async loadUsers(){
      const userDocs = await getDocs(collection(db, 'users'));
      this.users = userDocs.docs.map(user => {
        return {
          id: user.id,
          ... user.data(),
        };
      });
    },
    getChipColor(state){
      let color = '';
      switch (state) {
        case 0:
          color = 'blue';
          break;
        case 1:
          color = 'green';
          break;
        case 2:
          color = 'red';
          break;
        default:
          color = 'gray';
          break;
      }
      return color;
    },
  }
}
</script>