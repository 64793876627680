<template>
  <h1>Projekty</h1>
  <div class="app-container">
    <ProjectsList v-if="!isAdmin" :projects="projects"/>
    <ProjectsListAdmin v-else :projects="projects"/>
  </div>
</template>

<script>
import ProjectsList from '../components/project-components/ProjectsListUser.vue';
import ProjectsListAdmin from '../components/project-components/ProjectsListAdmin.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  components: {
    ProjectsList,
    ProjectsListAdmin,
  },
  setup(){
    const store = useStore();
    const currentUser = computed(() => store.getters.currentUser);
    const isAdmin = computed(() => currentUser.value.admin);

    return {
      isAdmin,
      currentUser
    }
  },
  data() {
    return {
      projects: [],
    };
  },
  computed: {
    ProjectsListComponent() {
      return this.isAdmin ? ProjectsListAdmin : ProjectsList;
    }
  }
}
</script>
<style lang="less">
@import '../assets/css/ProjectsView.less';
</style>