import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import logger from "./plugins/logger.js"
import { auth } from './firebase'; // ensure this is your correct path to firebase auth

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faHouse, faRightFromBracket, faUserTie, faTrash, faPen, faGears, faListCheck} from '@fortawesome/free-solid-svg-icons'

library.add(faHouse, faRightFromBracket, faUserTie, faTrash, faPen, faGears, faListCheck)

import vuetify from './plugins/vuetify'

let app;

auth.onAuthStateChanged(user => {
  if (!app) {
    app = createApp(App)
      .component('fa-icon', FontAwesomeIcon)
      .use(vuetify)
      .use(store)
      .use(router)
      .use(logger)
      .mount("#app");
  }

  if (user) {
    store.dispatch('fetchUser');
  }
});

