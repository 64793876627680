<template>
  <v-app>
    <side-bar-menu/> 
    <router-view />
  </v-app>
</template>

<script>
import SideBarMenu from './components/SidebarMenu.vue';

export default {
  components: {
    SideBarMenu
  }
}
</script>

<style lang="less">
body{
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  width: calc(100% - 50px);
  margin: auto;
  margin-left: 50px;
}
</style>
