import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from "firebase/auth";


export default {
  install(app) {
    app.config.globalProperties.logActivity = function (message, email) {
      const db = getFirestore();
      const user = getAuth().currentUser;


      if (user) {
        const colRef = collection(db, "logs", user.uid, "userLogs");
        addDoc(colRef, {
          message: message,
          user: email,
          timestamp: serverTimestamp()
        })
        // .then((docRef) => {
        //   console.log("Document written with ID: ", docRef.id);
        // })
        // .catch((error) => {
        //   console.error("Error adding document: ", error);
        // });
      } else {
        console.error("No user is signed in.");
      }
    }
  }
}