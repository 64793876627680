<template>
  <div class="list-container">
    <v-data-table 
      :headers="headers" 
      :items="flattenedProjects" 
      @click:row="goToProject">
      <template v-slot:item="{ item }">
        <tr style="text-align: left" @click="goToProject(item)">
            <td>{{ item.name }}</td>
            <td><v-chip 
               style="cursor: pointer;"
               :class="'s'+item.state_num"
               >{{ item.state }}</v-chip></td>
            <td>{{ item.created }}</td>
            <td>{{ item.deadline }}</td>
            <td>{{ item.annotate }}</td>
            <td>{{ item.check }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

export default {
  emits: ['selected'],
  props: {
    projects: Array
  },
  data() {
    return {
      localProjects: [],
      headers: [
        { title: 'Název projektu', key: 'name' },
        { title: 'Stav', key: 'state' },
        { title: 'Vytvořeno', key: 'created' },
        { title: 'Odevzdání', key: 'deadline' },
      ]
    };
  },
  computed: {
    flattenedProjects() {
    return this.localProjects.map(project => {
      const createdDate = new Date(project.created.seconds * 1000);
      const deadlineDate = new Date(project.deadline.seconds * 1000);

      let state;
      switch(project.state){
        case 0:
          state = 'Nový'
          break;
        case 1:
          state = 'Anotace'
          break;
        case 2:
          state = 'Anotace dokončena'
          break;
        case 3:
          state = 'Kontrola'
          break;
        case 4:
          state = 'Kontrola dokončena'
          break;
        case 5:
          state = 'Dokončeno'
          break;
      }

      return {
        id: project.id,
        name: project.name,
        created: `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`,
        deadline: `${deadlineDate.toLocaleDateString()} ${deadlineDate.toLocaleTimeString()}`,
        action: 'action',
        state: state,
        state_num: project.state,
      };
    });
  }
  },


  async created() {
    this.loadProjects();

  },
  methods: {
    goToProject(item){
      this.$router.push({ name: 'Projekt new', params: { id: item.id } });
    },

    async loadProjects() {
      const db = getFirestore();
      const auth = getAuth();
      const projectsRef = collection(db, 'projects');      
      
      const annotateQuery = query(projectsRef, where("annotate", "==", auth.currentUser.uid));
      const annotateSnapshot = await getDocs(annotateQuery);

      const checkQuery = query(projectsRef, where("check", "==", auth.currentUser.uid));
      const checkSnapshot = await getDocs(checkQuery);

      const projectDocs = [
        ...annotateSnapshot.docs,
        ...checkSnapshot.docs,
      ];

      this.localProjects = projectDocs.map(doc_instance => {
        const projectData = doc_instance.data();
        return { id: doc_instance.id, ...projectData };
      });
    },



  }
}



</script>